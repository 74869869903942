import {useEffect} from 'react';
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/Education.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
  AiFillFacebook,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home4() {

  return (
    <Container fluid className="project-section" >
      <Container>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND OUT MORE ABOUT ME ON</h1>
            <p>
            I'm searching for full-time positions in machine learning or software engineering right now! You can reach me by email at, if you are aware of any openings, if you have any queries, or if you simply want to say hello.<span className="purple"> Mwansagp@gmail.com</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/mwansaGP"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/Gee-Games-110042500879346"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/mwansa-greenwell-phiri-a4670b122"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/gee_games/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home4;
