import { useEffect } from 'react';
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import dawa from "../../Assets/dawa.ico";
import stackone from "../../Assets/so.ico";
import yu from "../../Assets/yu.ico";
import af from "../../Assets/af.ico";
import myImg from "../../Assets/Education.svg";
import UNZA from "../../Assets/unza.ico";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
  AiFillFacebook,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Home3() {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//cdn.credly.com/assets/utilities/embed.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container fluid className="project-section" >
      <Container>
        <Row>

          <Col className="home-about-description">
            <h1 style={{ fontSize: "4.6em" }}>
              <span className="purple"> EDUCATION </span>
            </h1>
            <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="02/2021 – 10/2021"
    contentStyle={{ background: '#1a2e20a9 ', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #1a2e20a9 ' }}
    iconStyle = {{
      display: 'flex', // Enables Flexbox
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      background: '#000000', // Background color
      color: '#fff', // Icon color
      width: '70px', // Width of the container
      height: '70px', // Height of the container
      borderRadius: '50%', // Makes the container circular
      overflow: 'hidden', // Ensures the icon stays within bounds
      fontSize: '50%', // Adjusts the icon size (for font-based icons)
    }}
    icon={<img src={UNZA} alt="My Icon" width="65" height="65" />}
  >
    <h3 className="vertical-timeline-element-title">Software Developer Intern at the university of Zambia's CICT Department</h3>
    <h4 className="vertical-timeline-element-subtitle">Lusaka,Zambia</h4>
    <p>
    During my internship we build web systems that will be used by both university staff
    members and students. This was a very educational experience for me
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#1a2e20a9 ', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #1a2e20a9' }}
    date="10/2021"
    iconStyle = {{
      display: 'flex', // Enables Flexbox
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      background: '#000000', // Background color
      color: '#fff', // Icon color
      width: '70px', // Width of the container
      height: '70px', // Height of the container
      borderRadius: '50%', // Makes the container circular
      overflow: 'hidden', // Ensures the icon stays within bounds
      fontSize: '50%', // Adjusts the icon size (for font-based icons)
    }}
    icon={<img src={UNZA} alt="My Icon" width="65" height="65" />}

   
  >
    <h3 className="vertical-timeline-element-title">Bachelor of Computer Science, Major Software
    Engineering</h3>
    <h4 className="vertical-timeline-element-subtitle">The University Of Zambia</h4>
    <h4 className="vertical-timeline-element-subtitle">Lusaka, Zambia</h4>
    <p>
    My undergraduate studies provided a solid foundation in computer science and programming, equipping me with the essential skills needed to kickstart my career. Through coursework and hands-on projects, I developed proficiency in coding, problem-solving, and software development fundamentals.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#1a2e20a9 ', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #1a2e20a9' }}
    date="01/2022 - 08/2024"
    iconStyle = {{
      display: 'flex', // Enables Flexbox
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      background: '#fff', // Background color
      color: '#fff', // Icon color
      width: '70px', // Width of the container
      height: '70px', // Height of the container
      borderRadius: '50%', // Makes the container circular
      overflow: 'hidden', // Ensures the icon stays within bounds
      fontSize: '50%', // Adjusts the icon size (for font-based icons)
    }}
    icon={<img src={dawa} alt="My Icon" width="65" height="65" />}

   
  >
    <h3 className="vertical-timeline-element-title">Senior Software Engineer, Dawa Health</h3>
    <h4 className="vertical-timeline-element-subtitle">Lusaka, Zambia</h4>
    <p>
    Dawa Health is an AI-powered network of mobile clinics democratizing access to
    health for pregnant women, nursing mothers, & paediatric patients
    </p>
  </VerticalTimelineElement>
 
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="06/2022 - 08/2024"
    contentStyle={{ background: '#1a2e20a9', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #1a2e20a9' }}
    iconStyle = {{
      display: 'flex', // Enables Flexbox
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      background: '#fff', // Background color
      color: '#fff', // Icon color
      width: '70px', // Width of the container
      height: '70px', // Height of the container
      borderRadius: '50%', // Makes the container circular
      overflow: 'hidden', // Ensures the icon stays within bounds
      fontSize: '50%', // Adjusts the icon size (for font-based icons)
    }}
    icon={<img src={stackone} alt="My Icon" width="65" height="65" />}
   
  >
    <h3 className="vertical-timeline-element-title">Lead Software Engineer, The Stackone
</h3>
    <h4 className="vertical-timeline-element-subtitle">Lusaka, Zmbia</h4>
    <p>
    s stackone we specialize in the development of cross platform systems that are
Used in different sectors from health to entertainment.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2023 - 08/2024"
    contentStyle={{ background: '#1a2e20a9', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #1a2e20a9 ' }}
    iconStyle = {{
      display: 'flex', // Enables Flexbox
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      background: '#000000', // Background color
      color: '#fff', // Icon color
      width: '70px', // Width of the container
      height: '70px', // Height of the container
      borderRadius: '50%', // Makes the container circular
      overflow: 'hidden', // Ensures the icon stays within bounds
      fontSize: '50%', // Adjusts the icon size (for font-based icons)
    }}
    icon={<img src={af} alt="My Icon" width="65" height="65" />}

  >
    <h3 className="vertical-timeline-element-title">Remote Software Engineer, AnvayaFeats/3S TECH & TALENT</h3>
    <h4 className="vertical-timeline-element-subtitle">Ohio, USA</h4>
    <p>
    Working remotely taught me valuable skills in project planning and teamwork. I learned to manage my time effectively, set clear priorities, and break down projects into manageable tasks, all while staying aligned with team goals. Collaboration became a key focus, as I communicated frequently with team members across different time zones, 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Present"
    contentStyle={{ background: '#1a2e20a9', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #1a2e20a9' }}
    icon={<img src={yu} alt="My Icon" width="65" height="65" />}
    iconStyle = {{
      display: 'flex', // Enables Flexbox
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      background: '#fff', // Background color
      color: '#fff', // Icon color
      width: '70px', // Width of the container
      height: '70px', // Height of the container
      borderRadius: '50%', // Makes the container circular
      overflow: 'hidden', // Ensures the icon stays within bounds
      fontSize: '50%', // Adjusts the icon size (for font-based icons)
    }}
  
  >
    <h3 className="vertical-timeline-element-title">Master’s in Artificial Intelligence at Yeshiva University</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
    <p>
      Driven by my deep passion for the field and a lifelong dream to work in AI. This program is an exciting step toward mastering advanced AI concepts and contributing to meaningful technological advancements.
    </p>
  </VerticalTimelineElement>
  
</VerticalTimeline>

          </Col>

        </Row>
      </Container>
    </Container>
  );
}
export default Home3;
