import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsPlayFill, BsYoutube } from "react-icons/bs";
import Cards from 'react-bootstrap/Card';
import vid from "./../../Assets/MediTracker.mp4";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiJava,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import {
  SiLaravel,
  SiFirebase,
  SiCsharp,
  SiFlutter,
  SiDart 
} from "react-icons/si";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
       <video
        src={props.imgPath}
        autoPlay
        loop
        muted
        style={{ width: "100%", height: "auto" }}
      ></video>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
