import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/L1.png";
import editor from "../../Assets/Projects/L3.png";
import chatify from "../../Assets/Projects/L4.png";
import suicide from "../../Assets/Projects/L7.png";
import bitsOfCode from "../../Assets/Projects/L2.png";
import vid from "./../../Assets/MediTracker.mp4";
import cam from "./../../Assets/cam.mp4";
import edu from "./../../Assets/edu.mp4";
import nt from "./../../Assets/Ntemba.mp4";
import med from "./../../Assets/Dawa.mp4";
import zc from "./../../Assets/Zam-Cab.mp4";

function Projects() {
  return (
    <Container fluid className="project-section" >
      <Particle />
      <Container>
  <h1 style={{ fontSize: "4.6em" }}>
    <strong className="purple">MY PROJECTS</strong>
  </h1>
  <p style={{ color: "white" }}>
    Here are a few projects I have developed over the years
  </p>
  <Row
    style={{
      justifyContent: "center",
      paddingBottom: "10px",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
      gap: "20px",
    }}
  >
    <Col className="project-card">
      <ProjectCard
        imgPath={vid}
        isBlog={false}
        title="MEDITRACKER"
        description="MediTracker is a powerful inventory management application designed specifically for pharmacies and hospitals to track drug stock levels, monitor expiration dates, and ensure effective inventory control. Developed using Flutter for cross-platform accessibility, MediTracker provides a user-friendly interface that allows healthcare professionals to manage medication inventory efficiently and minimize waste."
        demoLink=""
      />
    </Col>

    <Col className="project-card">
      <ProjectCard
        imgPath={cam}
        isBlog={false}
        title="CAMPUS-HUB"
        description="An innovative app designed to empower students by providing a platform to showcase their skills and sell products within their campus community. Whether you’re a student offering tutoring services, graphic design, or selling handmade crafts, School Hub makes it easy to connect with potential buyers and collaborators."
        demoLink=""
      />
    </Col>

    <Col className="project-card">
      <ProjectCard
        imgPath={med}
        isBlog={false}
        title="DAWA HEALTH"
        description="DawaClinian is a healthcare app designed to support pregnant women in managing their antenatal care journey. With features to track health progress, book appointments, and access essential information on pregnancy, DawaClinian serves as a comprehensive companion for expectant mothers. The app empowers women with easy access to healthcare resources, ensuring they stay informed and on track throughout their pregnancy."
        demoLink=""
      />
    </Col>

    <Col className="project-card">
      <ProjectCard
        imgPath={zc}
        isBlog={false}
        title="ZamCab"
        description="ZamCab is a user-friendly ride-sharing app designed to connect passengers with reliable drivers in real time, offering an affordable and convenient transportation solution. Developed to address local commuting needs, ZamCab provides a seamless experience for users seeking quick, secure, and efficient rides within their community."
        demoLink=""
      />
    </Col>

    <Col className="project-card">
      <ProjectCard
        imgPath={nt}
        isBlog={false}
        title="Ntemba24"
        description="Ntemba 24 is a versatile, multi-platform point-of-sale (POS) system tailored for small shops, stalls, and vendors. Designed to streamline sales and inventory management, Ntemba 24 also includes an integrated app where customers can browse and buy products directly, creating an efficient bridge between vendors and consumers. The system works seamlessly across devices, making it accessible for shop owners and customers alike."
        demoLink=""
      />
    </Col>

    <Col className="project-card">
      <ProjectCard
        imgPath={edu}
        isBlog={false}
        title="EduPlanner AI"
        description="EduPlanner AI is a cutting-edge application designed to empower teachers by leveraging the power of artificial intelligence. It simplifies lesson planning, assessment creation, and student progress tracking, enabling educators to focus on what they do best—teaching and inspiring their students."
        demoLink=""
      />
    </Col>
  </Row>
</Container>

    </Container>
  );
}

export default Projects;
