import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>

        <h1 style={{ fontSize: "4.6em"  }}>
            <span className="purple">MY SKILLSET</span>
            </h1>

        <Techstack />

        
        <h1 style={{ fontSize: "4.6em" }}>
            <span className="purple"> MY TOOLS</span>
            </h1>
        <Toolstack />

      
      </Container>
    </Container>
  );
}

export default About;
