import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/me.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";


function Home2() {
  return (
    <Container fluid className="project-section" id="about">

      <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Col md={10} className="home-about-description">
          <h1 style={{ fontSize: "4.6em" }}>
            <span className="purple"> ABOUT ME </span>
          </h1>
          <p className="home-about-body" style={{ fontSize: "4.6em" }}>
            I am a skilled software developer with a strong background in creating high-quality, efficient applications. With experience in Flutter and Dart programming, I excel in developing cross-platform apps with seamless performance. I’m also adept at working within Agile frameworks, which allows me to quickly adapt and effectively collaborate on iterative projects. My expertise includes implementing key features like database integration, user authentication, and media management, enabling me to build applications that are robust, secure, and user-friendly.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
export default Home2;
