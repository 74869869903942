import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import {
  AiOutlineHome, 
  AiOutlineUser,
} from "react-icons/ai";
import {
 
  IoLogoGameControllerB,
} from "react-icons/io";

import { CgFileDocument } from "react-icons/cg";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  const handleClickScroll = (Name) => {
    const element = document.getElementById(Name);
    if (element) {
      
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" 
                onClick={()=>{
                  updateExpanded(false);
                  handleClickScroll("home");}}
               
              >
                <AiOutlineHome /> HOME
              </Nav.Link>
            </Nav.Item>


            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/" 
                onClick={()=>{
                  updateExpanded(false);
                  handleClickScroll("about");}}
               
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> ABOUT ME 
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/" 
                onClick={()=>{
                  updateExpanded(false);
                  handleClickScroll("Project");}}
              >
                <IoLogoGameControllerB
                  style={{ marginBottom: "2px" }}
                />{" "}
                MY PROJECTS
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> RESUME
              </Nav.Link>
            </Nav.Item>

          
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
