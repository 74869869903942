import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-m.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Projects from "../../components/Projects/Projects";
import About from "../../components/About/About";
import Type from "./Type";
import Fade from 'react-reveal/Fade';

function Home() {
  return (
    <section>

      <Container fluid className="project-section" id="home">
        <Particle />
        <Container className="home-content" style={{ padding: '20px' }}>
      <Fade left>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col
            xs={12}
            md={7}
            className="home-head"
            style={{
              textAlign: 'center',
              padding: '10px',
            }}
          >
            <div style={{ padding: '5px' }}>
              <h1
                className="heading-name"
                style={{
                  fontSize: '5.8em', // Original font size
                  lineHeight: '1.2',
                }}
                class="responsive-heading-name"
              >
                I AM
                <br />
                <strong className="main-name">
                  MWANSA <br />GREENWELL <br /> PHIRI
                </strong>
              </h1>
            </div>
            <div style={{ padding: '5px' }}>
              <h1
                style={{
                  fontSize: '1.8em', // Original font size
                  lineHeight: '1.5',
                }}
                className="responsive-description"
              >
                Welcome to my portfolio website! Here, you’ll find examples of
                my work and a brief overview of my skills and experience.
              </h1>
            </div>
          </Col>
        </Row>
      </Fade>
    </Container>
      </Container>

      <Fade left>
        <section id="about" />
        <Home2 />
      </Fade>

      <Fade left>
        <section id="Education" />
        <Home3 />
      </Fade>


      <Fade top>
        <section id="Skills" />
        <About />
      </Fade>


      
        <section id="Project" />
        <Projects />
      
      <Fade left>
        <section id="Contact Me" />
        <Home4 />
      </Fade>

    </section>
  );
}

export default Home;
